const LeftSidebarContainer = ({
  open,
  children,
}: {
  open: boolean;
  children: any;
}) => {
  return (
    <div
      style={{ height: "calc(100vh - 80px)" }}
      className={`transition-all dark:text-white dark:border-neutral-700 duration-300 sticky top-20 flex-shrink-0 ${
        open
          ? `w-full px-8 md:px-0 md:ml-8 md:w-[16.75rem] overflow-y-auto`
          : "hidden overflow-hidden md:block md:w-[0px] p-0"
      }`}
    >
      {children}
    </div>
  );
};

export default LeftSidebarContainer;
