import * as React from "react";

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const Sparkles = ({ size = 24, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.6001 4.75C15.6001 7.51142 14.1115 10 11.3501 10C14.1115 10 15.6001 12.4886 15.6001 15.25C15.6001 12.4886 17.0887 10 19.8501 10C17.0887 10 15.6001 7.51142 15.6001 4.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.6001 12.75C8.6001 14.4069 7.00695 16 5.3501 16C7.00695 16 8.6001 17.5931 8.6001 19.25C8.6001 17.5931 10.1932 16 11.8501 16C10.1932 16 8.6001 14.4069 8.6001 12.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
