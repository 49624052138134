const CenterContainer = ({
  leftOpen,
  children,
}: {
  leftOpen: boolean;
  children: any;
}) => {
  return (
    <div className={`flex-grow ${leftOpen ? "hidden md:block" : null}`}>
      {children}
    </div>
  );
};

export default CenterContainer;
