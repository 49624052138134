const LeftSidebarBody = ({
  open,
  children,
}: {
  open: boolean;
  children: any;
}) => {
  return (
    <div
      className={`${
        open
          ? "opacity-100 visible delay-200 duration-500"
          : "opacity-0 invisible"
      } transition-all`}
    >
      {children}
    </div>
  );
};

export default LeftSidebarBody;
