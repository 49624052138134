import Link from "next/link";
import { useRef, useState, useEffect } from "react";

import { getRelativeTime, eventTypeToLabel } from "client/lib/helpers";
import { External } from "../icons/external";
import WalletLabel from "./WalletLabel";
import EventIcon from "./EventIcon";
import EasyImage from "@/client/components/common/EasyImage";
import CollectionHover from "./CollectionHover";
import SimpleButton from "./Button";
import { Bolt } from "../icons/bolt";
import { Sparkles } from "../icons/sparkles";
import PurchaseModal from "client/components/common/PurchaseModal";
import BidModal from "client/components/common/modal/BidModal";
import { getEtherscanTxUrl } from "client/lib/links";
import { WalletInfo } from "shared/types/walletInfo";
import { ActivityKind } from "shared/types/activity";
import EthLabel from "../frame-design-system/labels/EthLabel";

type ActivityItem = {
  tokenId: string;
  contractAddress: string;
  tokenName: string | null | undefined;
  eventType: "ask" | "sale";
  collectionName: string | null | undefined;
  collectionId: string | null | undefined;
  tokenImage: string | null | undefined;
  price?: number;
  priceCurrencySymbol?: string;
  fromAddress: string | null | undefined;
  fromAddressWalletInfo?: WalletInfo;
  toAddress?: string | null | undefined;
  toAddressWalletInfo?: WalletInfo;
  txHash?: string;
  newFloor?: boolean;
  assetRelistingCount?: number;
  createdAt?: Date;
  currentUserAddress?: string;
};

const ActivityItem = ({
  tokenId,
  tokenName,
  eventType,
  contractAddress,
  collectionName,
  collectionId,
  tokenImage,
  price,
  priceCurrencySymbol,
  fromAddress,
  fromAddressWalletInfo,
  toAddress,
  toAddressWalletInfo,
  txHash,
  newFloor,
  assetRelistingCount,
  currentUserAddress,
  createdAt,
}: ActivityItem) => {
  const [bidModalOpen, setBidModalOpen] = useState(false);
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);

  const [beforeLoad, setBeforeLoad] = useState<boolean>(true);
  const collectionHoverRef = useRef() as any;

  const animationClasses = beforeLoad
    ? "invisible opacity-0 -translate-y-24"
    : "visible opacity-100 translate-y-0";

  useEffect(() => {
    setBeforeLoad(false);
  }, []);

  const etherscanUrl = txHash ? getEtherscanTxUrl(txHash) : undefined;

  const DesktopItem = () => {
    return (
      <div
        className={`${animationClasses} activity-item dark:border-neutral-700 dark:hover:border-neutral-400 overflow-hidden relative hover:shadow-md transition-all hover:border-neutral-400 py-4 dark:text-white px-8 flex gap-2 lg:gap-8 xl:gap-12 items-center justify-between border rounded-2xl`}
      >
        <div className="w-32 flex-shrink-0">
          <p
            className={[
              `text-sm mt-1 inline-block pr-3 pl-1 rounded-full capitalize`,
              getEventKindLabelColors(assetRelistingCount),
            ].join(" ")}
          >
            <span className="flex items-center gap-1">
              <EventIcon size={16} type={eventType as string} />
              {getEventKindLabelText(eventType, assetRelistingCount)}
            </span>
          </p>
          {newFloor ? (
            <p className="text-sm mt-1 bg-purple-100 dark:bg-purple-900 inline-block pr-3 pl-1 rounded-full capitalize">
              <span className="flex items-center gap-1">
                <Sparkles className="dark:text-white" size={16} />
                New floor
              </span>
            </p>
          ) : null}
        </div>
        <div className="flex items-center flex-grow gap-2">
          <Link
            href={`/asset/${contractAddress}/${tokenId}`}
            className="hover:opacity-70 transition flex items-center gap-2">

            <EasyImage
              imageUrl={tokenImage as string}
              className="flex-shrink-0"
              size={44}
            />

          </Link>
          <div>
            <div
              ref={collectionHoverRef}
              className="line-clamp-1 cursor-pointer"
            >
              <Link href={`/collections/${collectionId}`}>

                <CollectionHover
                  label={collectionName as string}
                  containerRef={collectionHoverRef}
                  collectionId={collectionId as string}
                  posTop={24}
                />

              </Link>
            </div>
            <Link href={`/asset/${contractAddress}/${tokenId}`}>

              <p className="line-clamp-1">{tokenName}</p>

            </Link>
          </div>
        </div>
        <div className="w-24">
          <div>
            {price ? (
              <div className="text-right">
                <div className="flex justify-end items-center gap-1">
                  <EthLabel
                    weth={priceCurrencySymbol === "WETH"}
                    amount={price}
                  />
                </div>
              </div>
            ) : (
              <p className="text-right">---</p>
            )}
          </div>
        </div>
        <div className="w-24 hidden lg:block text-sm truncate text-right">
          {fromAddress ? (
            (<Link href={`/wallets/${fromAddress}`} className="hover:text-neutral-600">

              <WalletLabel
                address={fromAddress}
                notable={!!fromAddressWalletInfo?.tags?.length}
              />

            </Link>)
          ) : (
            "---"
          )}
        </div>
        <div
          className={`${
            toAddress ? "w-24" : "w-44 flex-shrink-0"
          } text-sm truncate text-right`}
        >
          {toAddress ? (
            (<Link href={`/wallets/${toAddress}`} className="hover:text-neutral-500">

              <WalletLabel
                address={toAddress}
                notable={!!toAddressWalletInfo?.tags?.length}
              />

            </Link>)
          ) : (
            <>
              <div className="justify-end w-full flex items-center gap-1">
                <ActionButtons />
              </div>
              <div className="2xl:hidden mt-2 text-neutral-600 dark:text-neutral-400 text-sm text-right">
                {createdAt ? (
                  <TimestampLink date={createdAt} url={etherscanUrl} />
                ) : null}
              </div>
            </>
          )}
        </div>
        <div className="hidden 2xl:block w-32 text-neutral-600 dark:text-neutral-400 text-sm text-right">
          {createdAt ? (
            <TimestampLink date={createdAt} url={etherscanUrl} />
          ) : null}
        </div>
      </div>
    );
  };

  const TabletItem = () => {
    return (
      <div className="border rounded-2xl p-3 dark:border-neutral-700">
        <div className="flex items-start">
          <div className="flex items-start flex-grow gap-3 text-sm">
            <Link
              href={`/asset/${contractAddress}/${tokenId}`}
              className="hover:opacity-70 transition flex items-center gap-2">

              <EasyImage
                imageUrl={tokenImage as string}
                className="flex-shrink-0"
                size={88}
              />

            </Link>
            <div>
              <Link href={`/asset/${contractAddress}/${tokenId}`}>

                <p className="line-clamp-1 text-lg">{tokenName}</p>

              </Link>
              <p className="text-sm">
                {eventType === "ask" ? (
                  <span>
                    Listed by{" "}
                    <WalletLabel address={fromAddress ? fromAddress : ""} />
                  </span>
                ) : (
                  <span>
                    Sold to <WalletLabel address={toAddress ? toAddress : ""} />
                  </span>
                )}
              </p>
              <div className="mt-1 flex items-center gap-1">
                <p
                  className={[
                    "text-sm mt-1 inline-block pr-2 pl-1 rounded-full capitalize",
                    getEventKindLabelColors(assetRelistingCount),
                  ].join(" ")}
                >
                  <span className="flex items-center gap-1">
                    <EventIcon size={16} type={eventType as string} />
                    {getEventKindLabelText(eventType, assetRelistingCount)}
                  </span>
                </p>
                {newFloor ? (
                  <p className="text-sm mt-1 bg-purple-100 dark:bg-purple-900 inline-block pr-2 pl-1 rounded-full capitalize">
                    <span className="flex items-center gap-1">
                      <Sparkles className="dark:text-white" size={16} />
                      New floor
                    </span>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="flex-shrink-0">
            <div>
              {price ? (
                <div className="text-right text-lg">
                  <div className="flex justify-end items-center gap-1">
                    <EthLabel
                      weth={priceCurrencySymbol === "WETH"}
                      amount={price}
                      size="large"
                    />
                  </div>
                </div>
              ) : (
                <p className="text-right">---</p>
              )}
            </div>
          </div>
        </div>

        <div className="mt-2 justify-between gap-2 flex items-end">
          <div className="text-neutral-600 dark:text-neutral-400 text-sm text-right">
            {createdAt ? (
              <TimestampLink date={createdAt} url={etherscanUrl} />
            ) : null}
          </div>
          <div className="flex items-center gap-2">
            <ActionButtons />
          </div>
        </div>
      </div>
    );
  };

  const MobileItem = () => {
    return (
      <div className="border rounded-2xl p-2 px-3 dark:border-neutral-700">
        <div className="flex items-start">
          <div className="flex items-center flex-grow gap-2 text-sm">
            <Link
              href={`/asset/${contractAddress}/${tokenId}`}
              className="hover:opacity-70 transition flex items-center gap-2">

              <EasyImage
                imageUrl={tokenImage as string}
                className="flex-shrink-0"
                size={44}
              />

            </Link>
            <div>
              <Link href={`/asset/${contractAddress}/${tokenId}`}>

                <p className="line-clamp-1 md:text-base">{tokenName}</p>

              </Link>
              <p className="text-xs md:text-sm">
                {eventType === "ask" ? (
                  <span>
                    Listed by{" "}
                    <WalletLabel address={fromAddress ? fromAddress : ""} />
                  </span>
                ) : (
                  <span>
                    Sold to <WalletLabel address={toAddress ? toAddress : ""} />
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className="flex-shrink-0">
            <div>
              {price ? (
                <div className="text-right">
                  <div className="flex justify-end items-center gap-1">
                    <EthLabel
                      weth={priceCurrencySymbol === "WETH"}
                      amount={price}
                    />
                  </div>
                </div>
              ) : (
                <p className="text-right">---</p>
              )}
            </div>
          </div>
        </div>
        <div className="mt-1 flex items-center gap-1">
          <p
            className={[
              "text-xs mt-1 inline-block pr-2 pl-1 rounded-md capitalize",
              getEventKindLabelColors(assetRelistingCount),
            ].join(" ")}
          >
            <span className="flex items-center gap-1">
              <EventIcon size={16} type={eventType as string} />
              {getEventKindLabelText(eventType, assetRelistingCount)}
            </span>
          </p>
          {newFloor ? (
            <p className="text-xs mt-1 bg-purple-100 dark:bg-purple-800 inline-block pr-2 pl-1 rounded-full capitalize">
              <span className="flex items-center gap-1">
                <Sparkles className="dark:text-white" size={16} />
                New floor
              </span>
            </p>
          ) : null}
        </div>
      </div>
    );
  };

  const TimestampLink = ({ date, url }: { date: Date; url?: string }) => {
    if (!url)
      return (
        <p className="truncate flex-grow">
          {date ? getRelativeTime(date) : null}
        </p>
      );

    return (
      <a
        href={url ? url : undefined}
        rel="noreferrer"
        target="_blank"
        className="flex items-center gap-1 hover:text-neutral-400 dark:hover:text-neutral-300 transition justify-end"
      >
        <p className="truncate flex-grow">
          {date ? getRelativeTime(date) : null}
        </p>
        <External className="flex-shrink-0" size={15} />
      </a>
    );
  };

  const ActionButtons = () => {
    // if the current user is the one who listed the item dont show buy buttons
    if (
      eventType === "ask" &&
      currentUserAddress?.toLowerCase() === fromAddress?.toLowerCase()
    )
      return null;

    return (
      <>
        <SimpleButton
          onClick={() => setPurchaseModalOpen(true)}
          className="px-4 py-2"
          icon={<Bolt className="w-4 h-4 -ml-1 mr-1" />}
        >
          Buy
        </SimpleButton>
        <SimpleButton
          onClick={() => setBidModalOpen(true)}
          variant="outline"
          className="px-4 py-2"
        >
          Offer
        </SimpleButton>
      </>
    );
  };

  return (
    <>
      <PurchaseModal
        tokenId={tokenId}
        contractAddress={contractAddress}
        open={purchaseModalOpen}
        setOpen={setPurchaseModalOpen}
      />

      <BidModal
        tokenId={tokenId}
        contractAddress={contractAddress}
        open={bidModalOpen}
        setOpen={setBidModalOpen}
      />
      <div>
        <div className="sm:hidden">
          <MobileItem />
        </div>
        <div className="hidden sm:block xl:hidden">
          <TabletItem />
        </div>
        <div className="hidden xl:block">
          <DesktopItem />
        </div>
      </div>
    </>
  );
};

const getEventKindLabelText = (
  kind: ActivityKind,
  assetRelistingCount?: number
) => {
  if (assetRelistingCount) return `${assetRelistingCount} · Relisted`;
  return eventTypeToLabel(kind);
};

const getEventKindLabelColors = (assetRelistingCount?: number) => {
  return assetRelistingCount
    ? "bg-orange-100 dark:bg-orange-900"
    : "bg-neutral-100 dark:bg-neutral-800";
};

export default ActivityItem;
