import { Close } from "../icons/close";
import { Made } from "../icons/made";
import { Send } from "../icons/send";
import { ShoppingBag } from "../icons/shopping-bag";
import { Sparkles } from "../icons/sparkles";
import Tag from "../icons/tag";

const EventIcon = ({ type, size }: { type: string; size: number }) => {
  const renderIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case "ask":
        return <Tag size={size} />;
      case "bid":
        return <Made size={size} />;
      case "sale":
        return <ShoppingBag size={size} />;
      case "transfer":
        return <Send size={size} />;
      case "ask_cancel":
        return <Close style={{ marginTop: 1 }} size={size} />;
      case "mint":
        return <Sparkles size={size} />;
      default:
        return null;
    }
  };

  return renderIcon(type);
};

export default EventIcon;
