import { useState } from "react";
import { useEnsName } from "wagmi";
import { formatWalletShort } from "client/lib/helpers";
import { useEffect } from "react";
import Star from "../icons/star";

const WalletLabel = ({
  address,
  shorten = true,
  notable = false,
}: {
  address: string;
  shorten?: boolean;
  notable?: boolean;
  notableReason?: string;
}) => {
  const [ensName, setEnsName] = useState<string | null>();

  const { data } = useEnsName({
    address: address as any,
    query: {
      staleTime: Infinity,
    },
  });

  useEffect(() => {
    setEnsName(data);
  }, [data]);

  return (
    <span className="relative">
      {notable ? (
        <div className="h-5 w-5 inline-block rounded-full border absolute -left-6">
          <Star
            className="inline-block text-orange-400 absolute"
            style={{ top: 2, left: 2 }}
            size={14}
          />
        </div>
      ) : null}
      {ensName ? (
        ensName
      ) : (
        <>{shorten ? formatWalletShort(address) : address}</>
      )}
    </span>
  );
};

export default WalletLabel;
